import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Link from '@mui/material/Link';
import Fade from 'react-reveal/Fade';
function Footer() {
  return (
    <div>
<Container fluid className="mt-5 footer_img2" >

    <Container id='contact'>
        <Row>
            <Col>
            <Fade bottom>
            <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>डॉ. विनोद कुमार वर्मा</h5>
           <p className='mx-3 text-center'style={{color:'#FBF2CF'}}>डॉ. विनोद कुमार वर्मा हिंदी और छत्तीसगढ़ी दोनों भाषाओं के मानक व्याकरण ग्रंथों की रचना करने वाले छत्तीसगढ़ के चर्चित साहित्यकार है| इन दोनों मानक ग्रंथों के प्रकाशन के लिए डॉ. विनोद कुमार वर्मा को संस्कृति विभाग व छत्तीसगढ़ राजभाषा आयोग से अनुदान प्रदान किया गया है| इन दोनों ग्रंथों के सह-लेखक भाषाविद डॉ. विनय कुमार पाठक है| </p>
           </Fade>
            </Col>
        </Row>
        <Row>
            <Col md={6} lg={6} sm={12}>
            <Fade bottom>
            <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>लोकप्रिय किताबें</h5>
            </Fade>
            <ul className="list-unstyled mb-5">
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#FBF2CF'}}>{'मछुआरे की लड़की'}</Link>
          
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#FBF2CF'}}>{' हिंदी का सम्पूर्ण व्याकरण'}</Link>
           
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#FBF2CF'}}>{'छत्तीसगढ़ी का सम्पूर्ण व्याकरण '}</Link>
       
          </li>
          {/* <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919'}}>{'Biography'}</Link>
          
          </li> */}
        </ul>
            </Col>
            <Col md={6} lg={6} sm={12}>
            <Fade bottom>
            <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>संपर्क</h5>
            </Fade>
            <ul className="list-unstyled mb-5">
          {/* <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#FBF2CF'}}>{'MIG - 59 नेहरू नगर, बिलासपुर, छत्तीसगढ़ 495001 '}</Link>
          
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#FBF2CF'}}>{'Mo. :- 98263-40331'}</Link>
           
          </li> */}
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#FBF2CF'}}>{'Email :- vinodverma8070@gmail.com'}</Link>
       
          </li>
          {/* <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919'}}>{'Biography'}</Link>
          
          </li> */}
        </ul>
            </Col>
        </Row>
    </Container>

  {/* <Container>
    <Row className='justify-content-center mt-5 mb-5'>
        <Col  md={1} lg={1} sm={12}></Col>
    <Col md={3} lg={3} sm={12}>
    <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>चर्चित पत्रिकाएं</h5>
    <p className="footer_list_item">पूर्व अध्यक्ष छत्तीसगढ़ राज्य भाषा आयोग
 ( राज्य मंत्री दर्जा ) छत्तीसगढ़ शासन, रायपुर (छ. ग.)</p>
    </Col>
    <Col md={3} lg={3} sm={12}>
    <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>लोकप्रिय किताबें</h5>
    <ul className="list-unstyled">
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'हिंदी साहित्य की वैचारिक पृष्ठभूमि'}</Link>
          
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'राजभाषा हिंदी प्रशिक्षण'}</Link>
           
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'छत्तीसगढ़ी लोक कथा'}</Link>
       
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'छत्तीसगढ़ी साहित्य और साहित्यकार'}</Link>
          
          </li>
        </ul>
    </Col>
    <Col md={3} lg={3} sm={12}>
    <h5 className="font-weight-bold footer_heading mt-5 mb-4" style={{ display: 'flex'}}>संपर्क</h5>
    <ul className="list-unstyled">
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'हिंदी साहित्य की वैचारिक पृष्ठभूमि'}</Link>
          
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'राजभाषा हिंदी प्रशिक्षण'}</Link>
           
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'छत्तीसगढ़ी लोक कथा'}</Link>
       
          </li>
          <li>
          <Link href="#" underline="hover" className="footer_list_item" style={{ display: 'flex',color:'#660919',fontWeight:'400'}}>{'छत्तीसगढ़ी साहित्य और साहित्यकार'}</Link>
          
          </li>
        </ul>
    </Col>   
   
    </Row>
  </Container> */}
  <Container>
    <Row>
    <footer class="text-center text-lg-start  text-muted">
  {/* <!-- Section: Social media --> */}
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
    {/* <!-- Left --> */}
    <div class="me-5 d-none d-lg-block">
      <span className="footer_list_item" >All Rights Reserved <strong>डॉ. विनोद कुमार वर्मा</strong> © 2022 | Designed & Developed By <Link href="https://www.primusinfosystems.com/" target="_blank" underline="hover" style={{ color:'#FBF2CF'}}><strong className='link'>Primus Info System.</strong></Link>.</span>
    </div>
    {/* <!-- Left --> */}

    {/* <!-- Right --> */}
    <div>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-google"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-github"></i>
      </a>
    </div>
    {/* <!-- Right --> */}
  </section>
  {/* <!-- Section: Social media --> */}
  </footer>
    </Row>
  </Container>
</Container>

    </div>
  )
}

export default Footer