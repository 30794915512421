import React from 'react'

import Vimarsh from '../components/Vimarsh';
function Vimarshpasge() {
  return (
    <div>
 
      <Vimarsh/>
 
    </div>
  )
}

export default Vimarshpasge