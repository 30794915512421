import { Container } from '@mui/system';
import React from 'react'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Slide from 'react-reveal/Slide';
//import Sonnet from '../../components/Sonnet';

function Tabs() {
  return (
    <div>
      <Container className='tab_design'>
 <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column tab">
            <Nav.Item>
              <Nav.Link eventKey="first">हिंदी और छत्तीसगढ़ी की प्रकाशित कृतियां</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">महाविद्यालय पाठ्यक्रम में शामिल कृतियां</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">विमर्श लेखन के साथ प्रकाशित छत्तीसगढ़ी काव्य की पुस्तकें</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth">संपादित हिंदी पुस्तकें/ न्यूज़ बुलेटिन</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {/* <Sonnet /> */}
              <div className='tab_bg'>
                <div className=' tab_bg_text'>
                <Slide bottom>
                <li className=' tab_bg_text_li' > <strong>हिंदी का सम्पूर्ण व्याकरण,</strong> डॉ. विनय कुमार पाठक एवं डॉ. विनोद कुमार वर्मा (2021, पृष्ठ 438)</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'><strong>छत्तीसगढ़ी का सम्पूर्ण व्याकरण,</strong>  डॉ. विनय कुमार पाठक एवं डॉ. विनोद कुमार वर्मा (2018, पृष्ठ 384)</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'>  <strong>"विमर्श के निष्कर्ष पर छत्तीसगढ़ी"</strong>(2018, छत्तीसगढ़ी भाषा की अनुसंधानपरक विवेचना  पृष्ठ 199) |</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'> <strong>"समकालीन विमर्श के बदलते प्रतिमान"</strong> डॉ. विनय कुमार पाठक एवं डॉ. विनोद कुमार वर्मा (2017, हिंदी और छत्तीसगढ़ी साहित्य की अनुसंधानपरक समीक्षा, पृष्ठ 300) </li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'> <strong>"छत्तीसगढ़ी का मानकीकरण" : मार्गदर्शिका,</strong> डॉ. विनोद  कुमार वर्मा एवं नरेंद्र कौशिक <strong>"अमसेनवी"</strong> (2022, छत्तीसगढ़ राज्य भाषा आयोग द्वारा प्रकाशित) |</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'><strong>"मेरी प्रतिनिधि कहानियां"</strong> (कहानी संग्रह, 2016)</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li mb-5'> <strong>"मछुआरे की लड़की" </strong>(हिंदी कहानी, 2000, चतुर्थी आवृति 2019) </li><br/><br/>
                </Slide>
                </div>
              {/* <p className=' tab_bg_text'>  </p> */}
              {/* <h2 className='tab_bg_text'>jjjjjjjjj</h2> */}
              </div>
          
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              {/* <Sonnet /> */}
              <div className='tab_bg'>
              <div className=' tab_bg_text'>
              <Slide bottom>
                <li className=' tab_bg_text_li' > <strong>बस्तर विश्वविद्यालय जगदलपुर एम. ए. हिंदी 2019-20,</strong> द्वितीय सेमेस्टर, प्रश्न पत्र अष्टम, आधुनिक गद्य साहित्य (उपन्यास, निबंध, एवं कहानी) <strong>कहानी : मछुआरे की लड़की</strong></li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li mb-5'><strong>रविशंकर विश्वविद्यालय रायपुर एम. ए. (छत्तीसगढ़ी) 2019-20,</strong>  तीसरा प्रश्न पत्र- छत्तीसगढ़ी के व्याकरण, 13 वा प्रश्न पत्र - छत्तीसगढ़ के वाक्य संरचना<strong> निर्धारित संदर्भ ग्रंथ : छत्तीसगढ़ी का सम्पूर्ण व्याकरण </strong>डॉ. विनय कुमार पाठक एवं डॉ. विनोद कुमार वर्मा</li><br/>
                </Slide>
                {/* <li className=' tab_bg_text_li'>  <strong>"विमर्श के निष्कर्ष पर छत्तीसगढ़ी"</strong>(2018, छत्तीसगढ़ी भाषा की अनुसंधानपरक विवेचना  पृष्ठ 199) |</li>
                <li className=' tab_bg_text_li'> <strong>"समकालीन विमर्श के बदलते प्रतिमान"</strong> डॉ. विनय कुमार पाठक एवं डॉ. विनोद कुमार वर्मा (2017, हिंदी और छत्तीसगढ़ी साहित्य की अनुसंधानपरक समीक्षा, पृष्ठ 300) </li>
                <li className=' tab_bg_text_li'> <strong>"छत्तीसगढ़ी का मानकीकरण" : मार्गदर्शिका,</strong> डॉ. विनोद  कुमार वर्मा एवं नरेंद्र कौशिक <strong>"अमसेनवी"</strong> (2022, छत्तीसगढ़ राज्य भाषा आयोग द्वारा प्रकाशित) |</li>
                <li className=' tab_bg_text_li'><strong>"मेरी प्रतिनिधि कहानियां"</strong> (कहानी संग्रह, 2016)</li>
                <li className=' tab_bg_text_li'> <strong>"मछुआरे की लड़की" </strong>(हिंदी कहानी, 2000, चतुर्थी आवृति 2019) </li> */}
              
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              {/* <Sonnet /> */}
              <div className='tab_bg'>
              <div className=' tab_bg_text'>
              <Slide bottom>
                <li className=' tab_bg_text_li'> कवयित्री बसंती वर्मा कृत 3 छत्तीसगढ़ी काव्य संग्रह का संपादन एवं विमर्श लेखन</li><br/>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'><strong> "मोर अंगना के फूल"</strong> (2012),</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'>  <strong>" मितानिन "</strong>(2013),</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li mb-5'> <strong>"मऊरे मोर आमा के डारा"</strong> (2015) </li><br/>
                </Slide>
                {/* <li className=' tab_bg_text_li'> <strong>"छत्तीसगढ़ी का मानकीकरण" : मार्गदर्शिका,</strong> डॉ. विनोद  कुमार वर्मा एवं नरेंद्र कौशिक <strong>"अमसेनवी"</strong> (2022, छत्तीसगढ़ राज्य भाषा आयोग द्वारा प्रकाशित) |</li>
                <li className=' tab_bg_text_li'><strong>"मेरी प्रतिनिधि कहानियां"</strong> (कहानी संग्रह, 2016)</li>
                <li className=' tab_bg_text_li'> <strong>"मछुआरे की लड़की" </strong>(हिंदी कहानी, 2000, चतुर्थी आवृति 2019) </li> */}
              
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              {/* <Sonnet /> */}
              <div className='tab_bg'>
              <div className=' tab_bg_text'>
              <Slide bottom>
                <li className=' tab_bg_text_li' > <strong>मासिक न्यूज़ बुलेटिन "संवाद"</strong> (मित्र मंडल बिलासपुर) का 12 वर्षों तक संपादन</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li'><strong>"संवाद सफरनामा" </strong> (2016) का संपादन</li>
                </Slide>
                <Slide bottom>
                <li className=' tab_bg_text_li mb-5'>  <strong>संचयन वार्षिकांक</strong>(कृषि महाविद्यालय, बिलासपुर) एवं <strong>समन्वय वार्षिकांक</strong>  (समन्वय साहित्य परिवार) का 18 वर्षों तक संपादन</li><br/>
                </Slide>
                {/* <li className=' tab_bg_text_li'> <strong>"समकालीन विमर्श के बदलते प्रतिमान"</strong> डॉ. विनय कुमार पाठक एवं डॉ. विनोद कुमार वर्मा (2017, हिंदी और छत्तीसगढ़ी साहित्य की अनुसंधानपरक समीक्षा, पृष्ठ 300) </li>
                <li className=' tab_bg_text_li'> <strong>"छत्तीसगढ़ी का मानकीकरण" : मार्गदर्शिका,</strong> डॉ. विनोद  कुमार वर्मा एवं नरेंद्र कौशिक <strong>"अमसेनवी"</strong> (2022, छत्तीसगढ़ राज्य भाषा आयोग द्वारा प्रकाशित) |</li>
                <li className=' tab_bg_text_li'><strong>"मेरी प्रतिनिधि कहानियां"</strong> (कहानी संग्रह, 2016)</li>
                <li className=' tab_bg_text_li'> <strong>"मछुआरे की लड़की" </strong>(हिंदी कहानी, 2000, चतुर्थी आवृति 2019) </li> */}
              
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    </Container>
    </div>
  )
}

export default Tabs