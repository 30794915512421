import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import '../App.css';
function Machhuware() {

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch('../Machuware ki beti.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'Machuware ki beti.pdf';
            alink.click();
        })
    })
}

  return (
    <div>
<Container>
  <Row className="mt-5 mb-5 ">
    <Col sm={12} md={4}>
    <Slide bottom>
    <Image className="bookdetail-img" src="./img/Machhuware.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
    </Slide>
    </Col>
    <Col sm={12} md={8} className='text-BD mt-5'> 
    <Fade bottom>
<h2 className="books_text " style={{textAlign:'left'}}>मछुआरे की लड़की</h2>
</Fade>
<Fade bottom>
<p> By <span>डॉ. विनोद कुमार वर्मा </span></p><br/>
</Fade>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}

{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<Fade bottom>
<p>  हिन्दी के सुप्रसिद्ध विद्वान, चिंतक,  समीक्षक  डाॅ. सत्येन्द्र शर्मा ने डाॅ. विनोद कुमार वर्मा कृत  ' मछुआरे की लड़की' को हिन्दी की श्रेष्ठ कहानियों में से एक माना है। प्रकृत्याँचल के बड़े केनवास पर उकेरी गई यह कहानी ' कथा शिल्प' की दृष्टिकोण से बेजोड़ है।  यह गरीब परिवार के घरौंदे में पली,  विकलाँगता का दंश झेल रही एक नामालूम-सी लड़की की नर्मदा के विकराल बाढ़ से जूझकर शताधिक लोगों को बचाने की, मानवीय संवेदनाओं को झकझोर देने वाली अद्भूत गाथा है। डाॅ. विनोद कुमार वर्मा की कलम से निःसृत इस कथा ने 30 अगस्त 1973 को होशंगाबाद में आई प्रलयंकारी बाढ़ से मछुआरन लड़की सरस्वती के जूझने की घटना को जीवांत कर दिया है। इस कथा को छत्तीसगढ के बस्तर विश्वविद्यालय सहित कई प्रदेशों के विश्वविद्यालयीन पाठ्यक्रम में शामिल किया गया है।.</p>
    {/* <h6><strong>pages:</strong> 192</h6> */}
    {/* <a href="../Machuware ki beti.pdf" download>Download Brochure</a> */}
    {/* <Button variant="danger" className='c-btn-without-arrow mb-3' onClick={onButtonClick}> Download Now</Button>{' '} */}
    </Fade>
    
  

    </Col>
    </Row>
</Container>
    </div>
  )
}

export default Machhuware