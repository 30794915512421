import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
function About() {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

  return (
    <div>
{/* <Container fluid >
    <Row>
        <Col md={12} lg={12} sm={12}> */}
        <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item className='firstslider'>
      <Row id='home'>
        <Col lg={6} md={6} sm={6}>
        <div  className='textslider'>
           <Zoom> <h3 className='text_slider_heading text-center'>डॉ. विनोद कुमार वर्मा </h3></Zoom>
           <Fade bottom><p className='text-center text_slider mt-4'>हिंदी और छत्तीसगढ़ी दोनों भाषाओं के मानक व्याकरण ग्रंथों की रचना करने वाले छत्तीसगढ़ के प्रसिद्ध साहित्यकार है|</p>   </Fade>
            </div>
        </Col>
        <Col lg={6} md={6} sm={6}>
            <div className='mb-5 '>
            <img className=" mt-3 img-fluid" src="./img/hand.png" alt="First slide" 
          style={{height:'400px',width:'400px',display:'flex',marginLeft:'25px'}}/>
            </div>
        
        </Col>
      </Row>
        {/* <img
          className="d-block w-100"
          src="holder.js/800x400?text=First slide&bg=373940"
          alt="First slide"
        /> */}
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item className='firstslider'>

      <Row>
        <Col lg={6} md={6} sm={6}>
        <div  className='textslider'>
           <Zoom> <h3 className='text_slider_heading text-center'>डॉ. विनोद कुमार वर्मा </h3></Zoom>
           <Fade bottom><p className='text-center text_slider mt-4'>हिंदी और छत्तीसगढ़ी दोनों भाषाओं के मानक व्याकरण ग्रंथों की रचना करने वाले छत्तीसगढ़ के प्रसिद्ध साहित्यकार है|</p>   </Fade>
            </div>
        </Col>
        <Col lg={6} md={6} sm={6}>
            <div className='mb-5 '>
            <img className=" mt-3 img-fluid" src="./img/hand.png" alt="First slide" 
          style={{height:'400px',width:'400px',display:'flex',marginLeft:'25px'}}/>
            </div>
        
        </Col>
      </Row>
    
      </Carousel.Item>
      <Carousel.Item className='firstslider'>
      <Row>
        <Col lg={6} md={6} sm={6}>
        <div  className='textslider'>
           <Zoom> <h3 className='text_slider_heading text-center'>डॉ. विनोद कुमार वर्मा </h3></Zoom>
           <Fade bottom><p className='text-center text_slider mt-4'>हिंदी और छत्तीसगढ़ी दोनों भाषाओं के मानक व्याकरण ग्रंथों की रचना करने वाले छत्तीसगढ़ के प्रसिद्ध साहित्यकार है| </p>   </Fade>
            </div>
        </Col>
        <Col lg={6} md={6} sm={6}>
            <div className='mb-5 '>
            <img className=" mt-3 img-fluid" src="./img/hand.png" alt="First slide" 
          style={{height:'400px',width:'400px',display:'flex',marginLeft:'25px'}}/>
            </div>
        
        </Col>
      </Row>
      </Carousel.Item>
    </Carousel>
            {/* <div >
            <h2 className='main_text_heading'>डॉ. विनोद कुमार वर्मा</h2><br/>
            <p className='mx-5 main_text'>हिंदी और छत्तीसगढ़ी दोनों भाषाओं के मानक व्याकरण ग्रंथों की रचना करने वाले छत्तीसगढ़ के एकमात्र साहित्यकार है|</p>
            </div> */}


        {/* </Col>
    </Row>
</Container> */}

<Container >
    <Row className='mt-5' id='about'>
        <Col>
        <Zoom>
        <h2 className='about_heading mb-3'>परिचय</h2>
        </Zoom>
        </Col>
    </Row>
</Container>

{/* about section start */}
<Container>
    <Row>

        <Col md={6} lg={6} sm={12}>
        
        <div>
<img className="img-fluid aboutimg mt-5 mb-4" src="./img/vinodji_fresh.jpeg" alt="First slide" 
          style={{height:'600px',width:'100%'}}/>
       
</div>
<Zoom>
<h2 className='about_name mb-3'>डॉ. विनोद कुमार वर्मा</h2>
</Zoom>
{/* <div className='mt-3'>
       <li type="none" className='pic_in_text'><strong> हिंदी का संपूर्ण व्याकरण</strong> (2021, पृष्ठ 438) संस्कृति विभाग से अनुदान प्राप्त</li>
       <li type="none" className='pic_in_text'><strong>छत्तीसगढ़ी का संपूर्ण व्याकरण</strong>(2018, पृष्ठ 384), छत्तीसगढ़ राजभाषा आयोग से अनुदान प्राप्त</li>
       <li type="none" className='pic_in_text'><strong> विमर्श के निष्कर्ष पर छत्तीसगढ़ी</strong> (2018, पृष्ठ 199) छत्तीसगढ़ राजभाषा आयोग से अनुदान प्राप्त</li>
       <li type="none" className='pic_in_text'><strong>छत्तीसगढ़ी का मानकीकरण : मार्गदर्शिका</strong>(2022), छत्तीसगढ़ राजभाषा आयोग द्वारा संघ प्रकाशित |</li>
       <li type="none" className='pic_in_text'><strong> समकालीन विमर्श के बदलते प्रतिमान</strong> (2017, पृष्ठ 300)</li>
       <li type="none" className='pic_in_text'><strong> ग्रंथों एवं हिंदी साहित्य की बहुचर्चित स्नातकोत्तर पाठ्यक्रम में शामिल कहानी "मछुआरे की लड़की"</strong> के रचयिता,<br/> हिंदी एवं आंचलिक साहित्य को पोषित करने वाले साहित्यकार व कृषि वैज्ञानिक</li>
       </div> */}
   
        </Col>

        <Col md={6} lg={6} sm={12}>

        <div className='about_bg mt-5 '>
          <div className='intro'>
          <Zoom>
             <p className='about_text'><strong>नाम - </strong> डॉ. विनोद कुमार वर्मा</p>
             </Zoom>
             <Zoom>
             <p className='about_textu'> <strong>पिता/माता -</strong> स्व. श्री भवानीलाल वर्मा /  स्व. श्रीमती आशालता वर्मा </p>
             </Zoom>
             <Zoom>
             <p className='about_textu'><strong>जन्म तिथि/स्थान - </strong> 26 अप्रैल 1954 फाल्गुन जिला :- जांजगीर - चाम्पा छत्तीसगढ़ </p>
             </Zoom>
             <Zoom>
             <p className='about_textu'><strong>शैक्षणिक योग्यता - </strong> बी.एस-सी. ( कृषि ), एम.एस-सी. ( कृषि ), शस्य विज्ञान, पी-एच.डी.(वनस्पति विज्ञान) </p>
             </Zoom>
             <Zoom>
             <p className='about_textu'> <strong> शासकीय सेवा- </strong> कृषि शिक्षण अनुसंधान, विस्तार एवं प्रसार का 39 वर्षों का अनुभव, कृषि महाविद्यालय बिलासपुर ( इंदिरा गाँधी कृषि वि. वि. रायपुर )से प्रध्यापक/मुख्य वैज्ञानिक ( शस्य विज्ञान ) पद से 30 अप्रैल 2019 को सेवानिवृत्त | </p>
             </Zoom>
             <Zoom>
             <p className='about_textu'> <strong>कृषि अनुसंधान आदि में योगदान  - </strong> इंदिरा गाँधी कृषि विश्वविद्यालय रायपुर द्वारा आयोजित शस्य विज्ञान की राष्ट्रीय संगोष्ठी (2006) में "बेस्ट पेपर अवार्ड" |</p>
             </Zoom>
             <Zoom>
            <p className='about_textu mb-5'> 
          
         
             <li>18 रिसर्च पेपर अंतर्राष्ट्रीय पत्र-पत्रिकाओं में प्रकाशित |</li>
           
             <li className='mb-5'> अंतर्राष्ट्रीय (02) व राष्ट्रीय (12) सेमिनार/सिंपोजियम में भागीदारी, 29 शोध-संक्षेप प्रकाशित|</li></p><br/><br/>
             </Zoom>
            </div><br/>
            </div>


        </Col>

    </Row>
</Container>


{/* about section end */}


<Container fluid className='about_second_bg '>
    <Row className='mt-5'>
        <Col  md={12} lg={12} sm={12} className='mt-3 '>
        <div >
          <Zoom>
             <h4 className='about_text mb-5'><strong>डॉ. विनोद कुमार वर्मा</strong> </h4>
             </Zoom>
             {/* <Zoom>
             <p className='about_textu'> <strong>पिता/माता -</strong> स्व. श्री भवानीलाल वर्मा /  स्व. श्रीमती आशालता वर्मा </p>
             </Zoom>
             <Zoom>
             <p className='about_textu'><strong>जन्म तिथि/स्थान - </strong> 26 अप्रैल 1954 फागुन जिला - जांजगीर चांपा छत्तीसगढ़ </p>
             </Zoom> */}
             <Zoom>
             <p className='about_textu mt-5'><strong> " 22 फरवरी 1954 को नवगठित सक्ती जिले के ग्राम फगुरम में जन्में छत्तीसगढ की माटी में पले-बढ़े डाॅ. विनोद कुमार वर्मा छत्तीसगढ के एक लोकप्रिय कृषि वैज्ञानिक तो हैं ही, इसके साथ ही हिन्दी और छत्तीसगढी साहित्य में उनका महती योगदान है। वे गहन संवेदना के कथाकार हैं। उनकी राष्ट्रीय स्तर पर चर्चित कहानी ' मछुआरे की लड़की ' को बस्तर विश्वविद्यालय सहित अनेक प्रदेशों के विश्वविद्यालयों के पाठ्यक्रम में शामिल किया गया है। प्रकृत्याँचल के बड़े केनवास पर उकेरी गई यह कहानी ' कथा शिल्प ' की दृष्टिकोण से बेजोड़ है। </strong></p>
             </Zoom>
             <Zoom>
             <p className='about_textu'> <strong>    उन्होंने डाॅ. विनय कुमार पाठक के साथ मिलकर दो मानक व्याकरण ग्रंथों का लेखन किया है-  ' छत्तीसगढी का सम्पूर्ण व्याकरण ' और  ' हिन्दी का सम्पूर्ण व्याकरण ' । इस तरह इन्हें छत्तीसगढ प्रदेश में दो भाषाओं के मानक व्याकरण ग्रंथों के लेखन का श्रेय  प्राप्त है। ये दोनों व्याकरण ग्रंथ लोक सेवा आयोग व व्यापम आदि के प्रतियोगी परीक्षा देने वाले विद्यार्थियों में अत्यन्त लोकप्रिय है। इसके साथ ही  ' समकालीन विमर्श के बदलते प्रतिमान ' एवं ' विमर्श के निष्कर्ष पर छत्तीसगढी ' नामक दो अनुसंधानपरक समीक्षा ग्रंथों का लेखन भी  इन्होंने किया है। इनका लघु उपन्यास ' पुनरावृत्ति ' व ' मेरी प्रतिनिधि कहानियाँ ' चर्चित रही हैं। </strong> </p>
             </Zoom>
             <Zoom>
             <p className='about_textu'> <strong> वे कई पत्र-पत्रिकाओं का वर्षों से संपादन करते रहे हैं। इसके साथ ' विकलांग विमर्श ' पर केन्द्रित अमलनेर, महाराष्ट्र में आयोजित अंतरराष्ट्रीय संगोष्ठी ( 17 से 19 जुलाई 2014) के अध्यक्ष मंडल में शामिल रहे हैं। डाॅ. वर्मा अनेक राष्ट्रीय संगोष्ठी के अतिथि वक्ता रहे हैं। 
       संप्रति, इंदिरा गाँधी कृषि विश्वविद्यालय रायपुर से सन् 2019 में प्राध्यापक पद से सेवानिवृत्ति के बाद अनेक सामाजिक व साहित्यिक संस्थाओं में वे सतत् सक्रिय हैं। डाॅ. विनोद कुमार वर्मा वर्तमान में ' मित्र मंडल ' नेहरूनगर, बिलासपुर के अध्यक्ष, ' तुलसी साहित्य अकादमी ' छत्तीसगढ के उपाध्यक्ष व ' अखिल भारतीय राष्ट्रीय शिक्षक संचेतना ' के राष्ट्रीय उपाध्यक्ष हैं। "</strong></p>
             </Zoom>
             <img className=" mt-3 img-fluid" src="./img/ink2r.png" alt="First slide" 
          style={{height:'200px',width:'400px',display:'flex',marginLeft:'25px',float:'right'}}/>
            </div><br/>
          
        </Col>
    </Row>
</Container>
    </div>
  )
}

export default About