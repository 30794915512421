import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import Slider from "react-slick";
import Card from 'react-bootstrap/Card';
import { Link} from 'react-router-dom';
 import Col from 'react-bootstrap/Col';
 import Fade from 'react-reveal/Fade';
 import Zoom from 'react-reveal/Zoom';
function Book() {
 
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     speed: 2000,
    //     autoplaySpeed: 2000,
    //     cssEase: "linear",

    //     responsive: [
    //         {
    //           breakpoint: 1024,
    //           settings: {
    //             slidesToShow: 3,
    //             slidesToScroll: 3,
    //              infinite: false,
    //             dots: true,
    //           }
    //         },
    //         {
    //           breakpoint: 768,
    //           settings: {
    //             slidesToShow: 2,
    //             slidesToScroll: 2,
    //             initialSlide: 2,
    //             infinite: false,
    //           }
    //         },
    //         {
    //           breakpoint: 480,
    //           settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1,
    //             infinite: false,
    //           }
    //         }
    //       ]

    //   };
  
  return (
    <div>

<Container>
    <Row>
        <Col>
        <Zoom>
        <h2 className='about_heading  mt-5 mb-5' id='book'>  प्रकाशित कृतिया</h2>
        </Zoom>
        </Col>
    </Row>
</Container>

<Container>
  <Row className='mb-5'>
    <Col md={3} lg={3} sm={12}>
    <div>
    <Link to="/hindi"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/front.jpg" /></Link>
      <Card.Body>
      <Link to="/hindi" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> हिंदी का सम्पूर्ण  व्याकरण</Card.Title></Link>
 
      </Card.Body>
          </div>
    </Col>

    <Col  md={3} lg={3} sm={12}>
    <div>
    <Link to="/cgvyakaran"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/front 1.jpg" /></Link>
      <Card.Body>
      <Link to="/cgvyakaran" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> छत्तीसगढ़ी का सम्पूर्ण  व्याकरण</Card.Title></Link>
 
      </Card.Body>
          </div>
    </Col>

    <Col  md={3} lg={3} sm={12}>
    <div>
    <Link to="/Machhuware_ki_ladki"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/Machhuware.jpg" /></Link>
      <Card.Body>
      <Link to="/Machhuware_ki_ladki" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> मछुआरे की लड़की</Card.Title></Link>
 
      </Card.Body>
          </div>
    </Col>

    <Col  md={3} lg={3} sm={12}>
    <div>
    <Link to="/Vimrash_ke_Nishkarsh"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/vimarsh.jpg" /></Link>
      <Card.Body>
      <Link to="/Vimrash_ke_Nishkarsh" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> विमर्श के निष्कर्ष पर छत्तीसगढ़ी</Card.Title></Link>
 
      </Card.Body>
          </div>
    </Col>
  </Row>

  <Row>
    <Col md={4} lg={4} sm={12}>
    <div>
    <Link to="/"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/samkalin.jpg" /></Link>
      <Card.Body>
      <Link to="/" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> समकालीन विमर्श के बदलते प्रतिमान</Card.Title></Link>
 
      </Card.Body>
          </div>
    </Col>

    <Col md={4} lg={4} sm={12}>
    <div>
    <Link to="/cg_ka_mankikaran"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/cg_margdarshika.jpg" /></Link>
      <Card.Body>
      <Link to="/cg_ka_mankikaran" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> छत्तीसगढी का मानकीकरण : मार्गदर्शिका</Card.Title></Link>
 
      </Card.Body>
          </div>
    </Col>

    <Col md={4} lg={4} sm={12}>
    <div>
    {/* <Link to="/"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/dokari.jpg" /></Link>
      <Card.Body>
      <Link to="/" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> डोकरी दाई मरगे</Card.Title></Link>
 
      </Card.Body> */}
      <Link to="/cgobjective_explain"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/cgexplain.jpeg" /></Link>
      <Card.Body>
      <Link to="/cgobjective_explain" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> छत्तीसगढ़ सम्पूर्ण वस्तुनिष्ठ( व्याख्यात्मक स्वरूप में )</Card.Title></Link>
 
      </Card.Body>
          </div>
    </Col>
  </Row>

</Container>
{/* slider section start */}

{/* <Container>
    <Row>
    <div>
 
        <Slider {...settings}>
          <div>
          <Link to="/"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/front.jpg" /></Link>
      <Card.Body>
      <Link to="/" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> हिंदी का संपूर्ण व्याकरण </Card.Title></Link>
       <Card.Text className='mb-1'>
       <Link to="/BookDetails" className='link mx-2'>   <strong style={{ marginLeft:'35px'}}> Georgia Ramirez</strong> </Link><br/>
        <span style={{ marginLeft:'37px'}}>₹ 399</span>
        </Card.Text>
      </Card.Body>
          </div>
          <div>
          <Link to="/"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/front 1.jpg" /></Link>
      <Card.Body>
      <Link to="/" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'> छत्तीसगढ़ी का संपूर्ण व्याकरण</Card.Title></Link>
 
      </Card.Body>
          </div>
          <div>
          <Link to="/"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/Machhuware.jpg" /></Link>
      <Card.Body>
      <Link to="/" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'>मछुआरे की लड़की</Card.Title></Link>
   
      </Card.Body>
          </div>
          <div>
          <Link to="/"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/samkalin.jpg" /></Link>
      <Card.Body>
      <Link to="/" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'>समकालीन विमर्श के बदलते प्रतिमान</Card.Title></Link>
  
      </Card.Body>
          </div>
          <div>
          <Link to="/"> <Card.Img variant="top" className='img-fluid slickslider-img mb-3 p-3' src="./img/dokari.jpg" /></Link>
      <Card.Body>
      <Link to="/" className='text-decoration-none'>  <Card.Title className='mb-4 books_text text-center'>विमर्श के निष्कर्ष पर छत्तीसगढ़ी</Card.Title></Link>
   
      </Card.Body>
          </div>
       
        </Slider>
      </div>
    </Row>
</Container> */}
 {/* slider section end */}


 {/* <Container>
<Row className=' mt-5'>
    <Col lg={6} md={6} sm={12}>
    <div class="card-horizontal mb-2">
                    <div class="img-square-wrapper p-3">
                    <Link to="/BookDetails"><img class="img-fluid img-what_trend" src="./img/vimarsh.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-3">
                    <Link to="/BookDetails" className='link text-decoration-none'>  <h4 class="books_text">विमर्श के निष्कर्ष पर छत्तीसगढ़ी</h4></Link>
                    <Link to="/AuthorkDetails" className='link'><h6><span> Author Name</span></h6></Link>
                    <p class="card-text">' विमर्श के निकष पर छत्तीसगढी ' का अर्थ है- ' समीक्षा की कसौटी पर छत्तीसगढी भाषा ' ( विमर्श = समीक्षा,  निकष = कसौटी )। जैसे ' सोना ' की कसौटी करने के बाद ही सोनार यह बताता है कि वह कितने कैरेट का है या कितना शुद्ध है। इस ग्रंथ में डाॅ विनोद कुमार वर्मा ने ' छत्तीसगढी भाषा' की ठीक वैसे ही कसौटी करते हुए छत्तीसगढी गद्य लेखन में फैली अराजकता की तथ्यपरक विवेचन कर उसके कारणों और विसंगतियों की ओर विद्वानों का ध्यान आकर्षित किया है। 
       इस ग्रंथ के बारे में सुप्रसिद्ध भाषाविद् डाॅ विनय कुमार पाठक ने लिखा है- ' यह ग्रंथ न केवल शोध अध्येताओं के लिए उपयोगी है बल्कि छत्तीसगढी भाषा को एक दिशा दिखाकर डाॅ विनोद कुमार वर्मा लेखक के रूप में छत्तीसगढी भाषा के श्रेष्ठ विमर्शक सिद्ध हुए हैं। यह ग्रंथ छत्तीसगढी भाषा-विमर्श को एक नया आयाम देगा,  इसमें दो मत नहीं। '.</p>
                    <span>₹300</span>
                    </div>
                </div>
    </Col>
    <Col lg={6} md={6} sm={12}>
    <div class="card-horizontal mt-2">
                    <div class="img-square-wrapper p-3">
                    <Link to="/BookDetails"> <img class="img-fluid img-what_trend" src="./img/Machhuware.jpg" alt="Card image cap"  /></Link>
                    </div>
                    <div class="card-body p-3">
                    <Link to="/BookDetails" className='link text-decoration-none'> <h4 class="books_text">मछुआरे की लड़की</h4></Link>
                    <Link to="/AuthorDetails" className='link'> <h6><span> Author Name</span></h6></Link>
                     <p class="card-text">    हिन्दी के सुप्रसिद्ध विद्वान, चिंतक,  समीक्षक  डाॅ सत्येन्द्र शर्मा ने डाॅ विनोद कुमार वर्मा कृत  ' मछुआरे की लड़की' को हिन्दी की श्रेष्ठ कहानियों में से एक माना है। प्रकृत्याँचल के बड़े केनवास पर उकेरी गई यह कहानी ' कथा शिल्प' की दृष्टिकोण से बेजोड़ है।  यह गरीब परिवार के घरौंदे में पली-पुसी,  विकलाँगता का दंश झेल रही एक नामालूम-सी लड़की की नर्मदा के विकराल बाढ़ से जूझकर शताधिक लोगों को बचाने की, मानवीय संवेदनाओं को झकझोर देने वाली अद्भूत गाथा है। डाॅ विनोद कुमार वर्मा की कलम से निःसृत इस कथा ने 30 अगस्त 1973 को होशंगाबाद में आई प्रलयंकारी बाढ़ से मछुआरन लड़की सरस्वती के जूझने की घटना को जीवांत कर दिया है। इस कथा को छत्तीसगढ के बस्तर विश्वविद्यालय सहित कई प्रदेशों के विश्वविद्यालयीन पाठ्यक्रम में शामिल किया गया है।.</p>
                    <span>₹300</span>
                    </div>
                </div>
    </Col> 


</Row>
</Container> */}

<Container>
    <Row className='mt-5 mb-5'>
        <Col md={6} lg={6} sm={12}>
<div className='box mt-5'>
<Fade>
<h3 className='lesson_heading mt-5 mb-4'> बस्तर विश्वविद्यालय, जगदलपुर</h3>
</Fade>
<Fade>
<p className='lesson_p'> एम. ए. हिंदी, द्वितीय सेमेस्टर, प्रश्न पत्र अष्टम, आधुनिक गद्य साहित्य (उपन्यास, निबंध, एवं कहानी)<br/>
<strong>पाठ्यक्रम में शामिल कहानियां</strong><br/>
पुरस्कार : जयशंकर प्रसाद <br/>
उसने कहा था : चंद्रधर शर्मा गुलेरी<br/>
ईदगाह : प्रेमचंद<br/>
वापसी : उषा प्रियंवदा<br/>
<strong>मछुआरे की लड़की : डॉ. विनोद कुमार वर्मा</strong></p>
</Fade>
</div>

        </Col>
        <Col md={6} lg={6} sm={12}  >
            <div className='box mt-5'>
            <Fade>
            <h3 className='lesson_heading mt-5 mb-4'>  रविशंकर विश्वविद्यालय रायपुर <br/> </h3>
            </Fade>
            <Fade>
<p className='lesson_p'> एम. ए. (छत्तीसगढ़ी)<br/>
<strong>तीसरा प्रश्न पत्र- छत्तीसगढ़ी के व्याकरण <br/>13 वा प्रश्न पत्र - छत्तीसगढ़ के वाक्य संरचना</strong><br/><br/>
निर्धारित संदर्भ ग्रंथ :<br/>
<strong>छत्तीसगढ़ी का सम्पूर्ण  व्याकरण <br/>डॉ. विनय कुमार पाठक एवं डॉ. विनोद कुमार वर्मा</strong></p>
</Fade>
            </div>
       
</Col>
    </Row>
</Container>
    </div>
  )
}

export default Book