import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Zoom from 'react-reveal/Zoom';
function Awards() {
  return (
    <div>

<Container>
    <Row className='mt-5'>
        <Col>
        <Zoom>
        <h2 className='about_heading mb-3' id='award'>योगदान</h2>
        </Zoom>
        </Col>
    </Row>
</Container>

<Container fluid  className='award_design mb-5'>
    <Row >
 <Col md={6} lg={6} sm={12} >
<div className=' img-fluid'>
<div className='award_text1 mx-5 mb-5'>
<Fade>
    <h3  className='mb-5' style={{textAlign:'center', color:'#660919',fontWeight:'600'}}>सहभागिता</h3>
    </Fade>
    <Flip top>
    <li>14 जून 2022 को राजनांदगांव में राष्ट्रीय शिक्षक संचेतना द्वारा <strong>"संत कबीर की वर्तमान प्रासंगिकता" </strong>विषय पर आयोजित राष्ट्रीय शोध संगोष्ठी के अतिथि वक्ता |</li><br/>
    </Flip>
    <Flip top>
    <li>11-12 जून 2022 को बिलासपुर में विकलांग चेतना परिषद द्वारा आयोजित <strong>"विकलांग विमर्श पर केंद्रित"</strong> राष्ट्रीय संगोष्ठी के अतिथि वक्ता |</li><br/>
    </Flip>
    <Flip top>
    <li>19 अक्टूबर 2021 को भिलाई में राष्ट्रीय शिक्षक संचेतना द्वारा <strong>"महिला सशक्तिकरण"</strong>  विषय पर आयोजित राष्ट्रीय शोध संगोष्ठी के अतिथि वक्ता |</li><br/>
    </Flip>
    <Flip top>
    <li> 21 जुलाई 2018 को <strong>"छत्तीसगढ़ी भाषा का मानकीकरण"</strong> विषय पर आयोजित राज्य स्तरीय संगोष्ठी (बिलासपुर छत्तीसगढ़) का संयोजक |</li><br/>
    </Flip>
    <Flip top>
    <li>विकलांग विमर्श पर केंद्रीय <strong>अंतर्राष्ट्रीय संगोष्ठी (अमलनेर) महाराष्ट्र 2014 के अध्यक्ष मंडल</strong> में शामिल |</li><br/>
    </Flip>
    <Flip top>
    <li> विकलांग विमर्श पर केंद्रित <strong>राष्ट्रीय संगोष्ठी</strong> (रायपुर 2010) के <strong>अतिथि वक्ता </strong>|</li><br/>
    </Flip>
    </div>
</div>
 </Col>
 <Col md={6} lg={6} sm={12} >
<div className='con_award_bg img-fluid'>
<div className='award_text1 mx-5 mb-5'>
<Fade>
    <h3  className='mb-5' style={{textAlign:'center', color:'#660919',fontWeight:'600'}}>सम्मान</h3>
    </Fade>
    <Flip top>
    <li> राष्ट्रीय शिक्षक संचेतना द्वारा 14 जून 2022 को राजनांदगांव में संत कबीर की प्रासंगिकता पर आयोजित राष्ट्रीय संगोष्ठी में <strong>"संत कबीर सम्मान"</strong> |</li><br/><br/>
    </Flip>
    <Flip top>
    <li>छत्तीसगढ़ी व हिंदी साहित्य में किए गए अभूतपूर्व आलोचना हेतु अखिल भारतीय विकलांग चेतना परिषद द्वारा साहित्य लेखन के लिए <strong>"विकलांग विमर्श का राष्ट्रीय सम्मान"</strong> (बिलासपुर 2017).</li><br/>
    </Flip>
    <Flip top>
    <li>लघु एवं सहायक उद्योग संघ बिलासपुर द्वारा अखिल भारतीय उद्योग एवं व्यापार मेला में <strong>"छत्तीसगढ़ रत्न" </strong> (2015) |</li><br/><br/>
    </Flip>
    <Flip top>
    <li> अनेक पंजीकृत संस्थाओं द्वारा राज्य स्तरीय सम्मान - <strong>"समन्वय रत्न (2001), आदर्श प्राध्यापक सम्मान (2014),  बिलासा साहित्य सम्मान (2015), छंद प्रेरणा सम्मान (2019), भारतीय जीवन बीमा निगम सम्मान (2019), रामदास अग्रवाल स्मृति सम्मान (2021) "</strong> |</li><br/><br/>
    </Flip>
    {/* <li>विकलांग विमर्श पर केंद्रीय <strong>अंतरराष्ट्रीय संगोष्ठी (अमलनेर) महाराष्ट्र 2014 के अध्यक्ष मंडल</strong> में शामिल |</li>
    <li> विकलांग विमर्श पर केंद्रित राष्ट्रीय संगोष्ठी (रायपुर 2010) के अतिथि वक्ता |</li><br/> */}
    </div>

</div>
 </Col>
    </Row>
</Container>
    </div>
  )
}

export default Awards