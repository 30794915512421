import React from 'react';
import './App.css';
import {HashRouter,Routes,Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from  './components/Home';
import Footer from './components/Footer';
import Vimarshpasge from './pages/Vimarshpasge';
import Machhuware from './pages/Machhuware';
import Hindi from './components/Hindi';
import Cg from './components/Cg';
import Margdarshika from './components/Margdarshika';
import Cgobjective from './components/Cgobjective';
import Scroll from './components/Scroll';
function App() {
  return (
    <HashRouter>
    <div className="App">
      <Scroll/>
      <Navbar/>
     <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/Vimrash_ke_Nishkarsh' element={<Vimarshpasge/>}/>
      <Route path='/Machhuware_ki_ladki' element={<Machhuware/>}/>
      <Route path='/hindi' element={<Hindi/>}/>
      <Route path='/cgvyakaran' element={<Cg/>}/>
      <Route path='/cg_ka_mankikaran' element={<Margdarshika/>}/>
      <Route path='/cgobjective_explain' element={<Cgobjective/>}/>
     </Routes>
 <Footer/>
    </div>
    </HashRouter>
  );
}

export default App;
