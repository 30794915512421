import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image'
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import '../App.css';
function vimarsh() {
  return (
    <div>

<Container>
  <Row className="mt-5 mb-5 ">
    <Col sm={12} md={4}>
    <Slide bottom>
    <Image className="bookdetail-img" src="./img/vimarsh.jpg" width={'100%'} height={'450px'}/>
    {/* <Button className="justify-content-center mb-5 mt-3" style={{ backgroundColor : '#F65D4E'}}>Subscribe to Our Newsletter for latest Update</Button>{' '} */}
    </Slide>
    </Col>
    <Col sm={12} md={8} className='text-BD mt-5'> 
    <Fade bottom>
<h2 className="books_text " style={{textAlign:'left'}}>विमर्श के निष्कर्ष पर छत्तीसगढ़ी</h2>
</Fade>
<Fade bottom>
<p> By <span>डॉ. विनोद कुमार वर्मा </span></p><br/>
</Fade>
{/* <p> ₹ 999.00 inclusive of all taxes</p> */}
{/* <Button variant="danger" className='c-btn-without-arrow mb-3'> Buy Now</Button>{' '} */}
{/* <Button style={{ backgroundColor : '#F65D4E'}}>Buy Now</Button>{' '} */}
<h6>About the book</h6>
<Fade bottom>
<p style={{textAlign:'justify'}}> ' विमर्श के निष्कर्ष पर छत्तीसगढी ' का अर्थ है- ' समीक्षा की कसौटी पर छत्तीसगढी भाषा ' ( विमर्श = समीक्षा,  निष्कर्ष = कसौटी )। जैसे ' सोना ' की कसौटी करने के बाद ही सोनार यह बताता है कि वह कितने कैरेट का है या कितना शुद्ध है। इस ग्रंथ में डाॅ. विनोद कुमार वर्मा ने ' छत्तीसगढी भाषा' की ठीक वैसे ही कसौटी करते हुए छत्तीसगढी गद्य लेखन में फैली अराजकता की तथ्यपरक विवेचना कर उसके कारणों और विसंगतियों की ओर विद्वानों का ध्यान आकर्षित किया है। 
       इस ग्रंथ के बारे में सुप्रसिद्ध भाषाविद् डाॅ. विनय कुमार पाठक ने लिखा है- ' यह ग्रंथ न केवल शोध अध्येताओं के लिए उपयोगी है बल्कि छत्तीसगढी भाषा को एक दिशा दिखाकर डाॅ. विनोद कुमार वर्मा लेखक के रूप में छत्तीसगढी भाषा के श्रेष्ठ विमर्शक सिद्ध हुए हैं। यह ग्रंथ छत्तीसगढी भाषा-विमर्श को एक नया आयाम देगा,  इसमें दो मत नहीं। '.</p>
    {/* <h6><strong>pages:</strong> 192</h6> */}
    </Fade>
    
  

    </Col>
    </Row>
</Container>

    </div>
  )
}

export default vimarsh