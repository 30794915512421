import React from 'react'
import About from './About';
 import Awards from './Awards';
 import Book from './Book';
// import Footer from './Footer';
import Tabs from './Tabs';
function Home() {
  return (
    <div>
<About/>
<Book/>
<Awards/>
<Tabs/>
    </div>
  )
}

export default Home