import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import {Link} from 'react-scroll';
// import { HashLink } from 'react-router-hash-link';
// import { NavHashLink } from 'react-router-hash-link';
function NavBar() {
  return (
    <div>
<Navbar collapseOnSelect expand="lg" className='navbar_design' style={{backgroundColor:'#4E6C50'}}>
    {/* style={{backgroundColor:'#ebe9eb' #b8a747 #ebd197}} */}
      <Container>
        <Navbar.Brand href="" className='text-nav footer_heading'>डॉ. विनोद कुमार वर्मा</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor:'#FBF2CF'}}/>
        <Navbar.Collapse id="responsive-navbar-nav" style={{height:'80px'}}>
        
          <Nav className="ms-auto float-left" >
          <Nav.Link href="/" className='text-nav-new nav-link'>  होम</Nav.Link>
         {/* <Nav.Link href="/"> <Link  className='text-nav nav-link'>
          होम
          </Link> </Nav.Link> */}
            <Nav.Link> <Link activeClass="active" className='text-nav nav-link' to="about" spy={true} smooth={true} offset={50} duration={500}>
            व्यक्तित्व
        </Link></Nav.Link>
            {/* <Nav.Link href="/#/Allbooks" className='text-nav'>पुस्तकें</Nav.Link> */}
            <Nav.Link >  <Link activeClass="active" className='text-nav nav-link' to="book" spy={true} smooth={true} offset={50} duration={500}>
            पुस्तकें
        </Link></Nav.Link>
            {/* <NavHashLink to="#book" activeClassName="selected" className='text-nav' activeStyle={{ color: 'red' }}>पुस्तकें</NavHashLink> */}
            <Nav.Link> <Link activeClass="active" className='text-nav nav-link' to="award" spy={true} smooth={true} offset={50} duration={500}>
            सम्मान
        </Link></Nav.Link>
            <Nav.Link><Link activeClass="active" className='text-nav nav-link' to="contact" spy={true} smooth={true} offset={50} duration={500}>
            संपर्क करें
        </Link></Nav.Link>
          </Nav>
      
       
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}

export default NavBar